import React, { useContext, useEffect, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';

import fetch from 'logic/rest/FetchProvider';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from './types';
import useFetch from "./common/hooks/useFetch";

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize: React.FC<StepAuthorizeProps> = ({
  onSubmit,
  onChange,
}) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { connectionString } = formData;

    const [F5ValidationStatus, setF5Validation] = useFetch(
      null,
      onSubmit,
      'POST',
      {
        management_ip: formData?.managementIP?.value || '',
        username: formData?.username?.value || '',
        password: formData?.password?.value || '',
      },
       );

       useEffect(() => {
         setF5Validation(null);

         if (F5ValidationStatus.error) {
           setFormError({
             full_message: F5ValidationStatus.error,
             nice_message: 'Unable to connect to Symantec EDR using provided configuration.',
           });
         }

         return () => {
           setFormError(null);
         };
       }, [F5ValidationStatus.error]);

       const handleSubmit = () => {
         setF5Validation(ApiRoutes.INTEGRATIONS.F5Networks.CHECK_CREDENTIALS);
       };

  const isFormValid = formValidation.isFormValid(
    ['f5Name', 'managementIP', 'username', 'password'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify Connection &amp; Proceed"
              disabled={isFormValid}
              loading={F5ValidationStatus.loading}
              title=""
              error={formError}
              description="">
      <ValidatedInput className=""
                      id="f5Name"
                      type="text"
                      fieldData={formData.f5Name}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={connectionString?.value}
                      required />

      <ValidatedInput className=""
                      id="managementIP"
                      type="text"
                      fieldData={formData.managementIP}
                      onChange={onChange}
                      label="Management server IP"
                      help="The IP Address of your F5 Management server"
                      autoComplete="off"
                      required />

      <ValidatedInput className=""
                      id="username"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.username}
                      label="User name"
                      help="User account with read access to logs."
                      required />

      <ValidatedInput className=""
                      id="password"
                      type="password"
                      onChange={onChange}
                      fieldData={formData.password}
                      label="Password"
                      help="A secret string of the user to prove its identity when requesting a token."
                      required />
    </FormWrap>
  );
};

export default StepAuthorize;
